import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import title_image from '../../images/titles/business_architecture.jpg'


const pageTitle = "Business Architecture";
const pageSlug = "business-architecture";


class BusinessArchitecture extends React.Component{
    render(){
      return(
        <Layout>

<section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Our experts provide you with professional advice on Business Architecture that gives an insight into how to develop your company.</h6>
        </div>
</section>

<section id="p_business_consulting" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    
                  <h3>You will need an effective business model to achieve business transformation goals. Getting a competent market infrastructure makes it possible to offer low-cost and high-quality products and services.</h3>
                  <p>Good business architecture should describe the technologies, processes, organizational structure, functions and skills, metrics, and infrastructure framework that can be tailored to support new business strategies.
                  </p>
                  <p>Our system, strategy and engineering experts will collaborate with you to provide seamless services in business architecture.
                  </p>
                  <span className="break"></span>
                  <h4>The strengths of our services </h4>
                  <span className="break"></span>
                  <h5>We offer a broad range of services including:
                  </h5>
                  <ul>
                    <li>System Architecture</li>
                    <li>Business and Engineering Architecture</li>
                    <li>Development and Modeling capabilities</li>
                    <li>Description and development of organizational roles</li>
                  </ul>
                  <h5>Our Capability Development and Modeling Business Architecture 
                  </h5>
                  <p>Services is one of the main core skills that plays a critical role in delivering value to the market.  Our experts work with you to identify desired business results and development criteria for these capabilities, providing important inputs to the structure of organizations, processes and technology.</p>
                  <h5>System Architecture </h5>
                  <p>Our experts use deep system automation and development skills to help you create your own custom process architectures. We also create and use lean and Six Sigma concepts to further improve the process performance. 
                  </p>
                  <p>We work with you to define the most appropriate structure based on your key skill criteria and business goals. Whether it is process-based, operational, consumer-based, or a model based on a product. We enable you to identify the key skills needed to support your organization.</p>
                  <span className="break"></span>
                  <span className="break"></span>
                  <span className="break"></span>
                  <hr/>
                  <p>The technology and infrastructure of business and technology architecture are constantly evolving
Find out more about what you can do for your company to develop your market structure!
                  </p>
                  <p><a href="">Learn more today</a></p>


                    
                </div>
            </div>
        </div>
      </section>



</Layout>
    )
  }
}

export default BusinessArchitecture
